<template>
  <div>
    <v-dialog
      v-if="dialog"
      v-model="dialog"
      max-width="1000px"
      persistent
      scrollable
    >
      <v-card title="Pontos de Impactação">
        <v-card-title>
          <card-title-with-back-button
            title="Pontos de Impactação"
            @back="close"
          />
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(ip, index) in impactionPoints"
              :key="index"
              class="border-bottom-grey pa-3"
              @click="selectPar(ip.pointOrderId)"
            >
              <v-list-item-content>
                <div class="d-flex">
                  <tooltip-icon
                    v-if="isParAlreadyAddedToDiagnosis(ip)"
                    color="primary"
                    icon="radio_button_checked"
                    text="Par já adicionado ao diagnóstico"
                    class="mr-2"
                  />
                  <tooltip-icon
                    v-else
                    color="grey"
                    icon="radio_button_unchecked"
                    text="Par não adicionado ao diagnóstico"
                    class="mr-2"
                  />
                  <span class="body-1">
                    {{ selectedTrackingPoint.name }} -
                    {{ ip.name }}
                  </span>
                </div>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>open_in_new</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            large
            class="mt-3"
            outlined
            color="black"
            width="100%"
            @click="close"
          >
            <v-icon>arrow_back</v-icon>
            Voltar ao rastreio
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  computed: {
    dialog: {
      get() {
        return this.$store.state.dialogs.pares.impactionPoints;
      },
      set(value) {
        this.$store.commit("dialogs/setParesImpactionPoints", value);
      },
    },
    impactionPoints() {
      return this.$store.getters["points/impactionPoints"];
    },
    selectedTrackingPoint() {
      return this.$store.getters["points/selectedTrackingPoint"];
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    async selectPar(pointOrderId) {
      await this.$store.dispatch("pares/selectPar", pointOrderId);
    },
    isParAlreadyAddedToDiagnosis({ parId }) {
      return this.$store.getters["diagnosis/addedPares"].some(
        (diagnosePar) => diagnosePar.par.id === parId
      );
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
  margin-bottom: 0 !important;
}
</style>
